import { getUrlParameters, newURLSearchParams } from "../utilities/urlHelper";
import { useState, useEffect } from "react";
const useUrlParamsContext = () => {
  const [urlParams, setUrlParams] = useState({}); //urlParams, setUrlParams

  useEffect(() => {
    setUrlParams(getUrlParameters());

    const handleUrlChange = () => {
      setUrlParams(getUrlParameters());
    };

    window?.addEventListener("popstate", handleUrlChange);

    return () => {
      window?.removeEventListener("popstate", handleUrlChange);
    };
  }, []);

  /**
   * this does the following thing,
   * 1. update the urlParams variable
   * 2. update the param to browser search bar
   * 3. remove the param to browser search bar if its empty
   * @param {*} newParams
   * @param {boolean} syncUrl if false skip the point 2 & 3
   * @param {boolean} reload if true reload the page after updating the urlParams
   * @return {void}
   */
  const updateUrlParams = (newParams, syncUrl = true, reload = false) => {
    if (!syncUrl) {
      setUrlParams({
        ...urlParams,
        ...newParams,
      });
      return;
    }

    const updatedParams = newURLSearchParams(window?.location.search);

    Object.keys(newParams).forEach((key) => {
      if (newParams[key] === "" || newParams[key] == null) {
        updatedParams.delete(key); // Remove the param if it's empty
      } else {
        updatedParams.set(key, newParams[key]);
      }
    });

    // Update the browser URL without reloading the page
    const newQueryString = updatedParams.toString();
    window?.history.pushState({}, "", newQueryString ? `?${newQueryString}` : window?.location.pathname);

    setUrlParams(getUrlParameters());
    if (reload) {
      window.location.reload();
    }
  };

  return { urlParams, updateUrlParams };
};

export default useUrlParamsContext;
