import React, { createContext } from "react";
import useContextUrlParams from "../hooks/useContextUrlParams";
import useContextDonation from "../hooks/useContextDonation";
import useContextContact from "../hooks/useContextContact";
import useContextFormInputsValidation from "../hooks/useContextFormInputsValidation";

export const Global = createContext();

const GlobalContextProvider = (props) => {
  const urlParams = useContextUrlParams();
  const donation = useContextDonation();
  const contact = useContextContact();
  const formInputsValidation = useContextFormInputsValidation();

  return (
    <Global.Provider
      value={{
        ...urlParams,
        ...donation,
        ...contact,
        ...formInputsValidation,
      }}
    >
      {props.children}
    </Global.Provider>
  );
};

export default GlobalContextProvider;
