/**
 * Checks if a value is empty.
 * @param {*} v - The value to check.
 * @returns {boolean} - Returns true if the value is empty, otherwise false.
 */
export const empty = (v) => {
  if (typeof v === "undefined") return true;
  if (v === null) return true;
  if (v === false) return true;
  if (typeof v === "string" || v.constructor === Array) return v.length === 0;
  if (v.constructor === Object) return Object.keys(v).length === 0;
  return false;
};

export const validateCard = (value, acceptedCards) => {
  // remove all non digit characters
  value = value.replace(/\D/g, "");
  let sum = 0;
  let shouldDouble = false;
  // loop through values starting at the rightmost side
  for (let i = value.length - 1; i >= 0; i--) {
    let digit = parseInt(value.charAt(i));

    if (shouldDouble) {
      if ((digit *= 2) > 9) digit -= 9;
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }

  const valid = sum % 10 === 0;
  let accepted = false;

  // loop through the keys (visa, mastercard, amex, etc.)
  Object.keys(acceptedCards).forEach(function (key) {
    const regex = acceptedCards[key];
    if (regex.test(value)) {
      accepted = true;
    }
  });

  return valid && accepted;
};

export const kebabCase = (str) => {
  if (!str) return null;

  // Remove special characters
  str = str.replace(/[^\w\s-]/g, "");

  return str
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/\s+/g, "-")
    .toLowerCase();
};

export const reverseKebabCase = (str) => {
  if (!str) return null;

  return str
    .replace(/-+/g, " ")
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .toLowerCase()
    .replace(/^\w/, (c) => c.toUpperCase());
};
