/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import React from "react";

import GlobalContextProvider from "./src/context/globalContextProvider";

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>;
};

// Scroll to hash id on initial load
// https://github.com/gatsbyjs/gatsby/issues/25056#issuecomment-645533198
// NOTES: The behaviour to scroll to the hash id and return to the same position remembered is expected.
// The second scroll only happened when user visited the page, and its scrolling to their last position.
// let offsetY = 0;
// const getTargetOffset = (hash) => {
//   const id = window.decodeURI(hash.replace(`#`, ``));
//   if (id !== ``) {
//     const element = document.getElementById(id);
//     if (element) {
//       const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
//       const clientTop = document.documentElement.clientTop || document.body.clientTop || 0;
//       const computedStyles = window.getComputedStyle(element);
//       const scrollMarginTop =
//         computedStyles.getPropertyValue(`scroll-margin-top`) || computedStyles.getPropertyValue(`scroll-snap-margin-top`) || `0px`;

//       return element.getBoundingClientRect().top + scrollTop - parseInt(scrollMarginTop, 10) - clientTop - offsetY;
//     }
//   }
//   return null;
// };

// export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
//   const hash = location.hash ?? window.location.hash;
//   console.log(hash);
//   if (hash) {
//     setTimeout(() => {
//       document.querySelector(hash).scrollIntoView({ behavior: "smooth" });
//       console.log(hash);
//     }, 100);
//     return false;
//   }

//   return true;
// };

export const onRouteUpdate = ({ location }) => {
  const hash = location.hash;
  if (hash) {
    setTimeout(() => {
      document.querySelector(hash).scrollIntoView({ behavior: "smooth" });
    }, 100);
  }
};

window.addEventListener("pageshow", function (event) {
  const hash = window.location.hash;
  if (hash) {
    // Ensure the browser has a moment to complete any initial rendering.
    setTimeout(() => {
      document.querySelector(hash)?.scrollIntoView({ behavior: "auto" });
    }, 100); // Adjust timeout as necessary
  }
});

export const onInitialClientRender = () => {
  //make the document always show scroll on toop when refresh
  const hash = window.location.hash;
  // console.log(hash);
  if (hash) {
    setTimeout(() => {
      document.querySelector(hash).scrollIntoView({ behavior: "smooth" });
    }, 100);
  }
};
