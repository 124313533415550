import { useState } from "react";

const useContextContact = () => {
  const [contactState, setContactState] = useState("");
  const [contactTopic, setContactTopic] = useState("");

  return { contactState, setContactState, contactTopic, setContactTopic };
};

export default useContextContact;
