import { useEffect, useState, useRef } from "react";
import creditCardType from "credit-card-type";
import { validateCard } from "../utilities/helper";
import { POST_LABEL } from "../utilities/constants";
import { regexValidPostalAddress, regexTextOnly, regexValidSuburb, regexCreditCards, regexValidEmail } from "../utilities/regex";

const EMPTY_FORM_POST = {
  [POST_LABEL.FIRST_NAME]: "",
  [POST_LABEL.LAST_NAME]: "",
  [POST_LABEL.ORGANIZATION_NAME]: "",
  [POST_LABEL.EMAIL]: "",
  [POST_LABEL.POSTCODE]: "",
  [POST_LABEL.ADDRESS]: "",
  [POST_LABEL.SUBURB]: "",
  [POST_LABEL.NAME_ON_CARD]: "",
  [POST_LABEL.CARD_TYPE]: "",
  [POST_LABEL.CARD_NUMBER]: "",
  [POST_LABEL.EXPIRY_MONTH]: "",
  [POST_LABEL.EXPIRY_YEAR]: "",
  [POST_LABEL.CCV]: "",
  [POST_LABEL.PAYMENT_METHOD]: "",
  [POST_LABEL.PAYPAL_DESC]: "Payment from Starlight",
  [POST_LABEL.PHONE]: "",
  [POST_LABEL.MESSAGE]: "",
};

const { visa, mastercard, amex } = regexCreditCards;
const acceptedCards = { visa, mastercard, amex };
/**
 *
 */
const useFormInputsValidationContext = () => {
  const formElement = useRef();
  const [isFormValid, setIsFormValid] = useState(false);
  const [stickyHoney, setStickyHoney] = useState("");
  const [formPost, setFormPost] = useState(EMPTY_FORM_POST);
  const [isFormLoaded, setIsFormLoaded] = useState(false);
  const [activeSelection, setActiveSelection] = useState(""); //activeTab, setActiveTab (updates, when selection.js contentful field updates)

  useEffect(function onLoad() {
    setIsFormLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    function onSelectionTabUpdate() {
      if (isFormLoaded) validateForm();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeSelection]
  );

  const onChangeInput = (e) => {
    switch (e.target.id) {
      case "card-number":
        handleInputCardNumber(e);
        break;
      case "expiry-date":
        handleInputExpiryDate(e);
        break;
      case "phone-number":
      case "phonenumber":
      case "phone":
        handleInputPhoneNumber(e);
        break;
      case "cvv":
        handleInputCvv(e);
        break;
      case "postcode":
      case "postcode-persistent":
        handleInputPostCode(e);
        break;
      case "company-name":
      case "message":
        handleInputJustCheckEmpty(e);
        break;
      case "postal-address":
        handleInputPostalAddress(e);
        break;
      case "suburb":
        handleInputSuburb(e);
        break;
      case "email":
        handleInputEmail(e);
        break;
      default:
        if (e.target.type === "text") {
          handleInputText(e);
        }
        break;
    }
  };

  const validateForm = () => {
    if (!formElement.current) return;
    const formData = [...formElement.current];
    //get all form elements including buttons, div, p, input, etc
    const allFormElements = formData.map(function (e) {
      return {
        uniqueId: e.id,
        isValid: e.dataset.isValidate, // value: e.dataset.isValidate,
        disabled: e.disabled,
        value: e.value, // inputValue: e.value,
      };
    });

    const formInputs = allFormElements.filter((e) => e.disabled === false && e.uniqueId !== "Addadollaramount" && e.uniqueId !== "");
    let tempFormPost = { ...formPost };

    const getCreditCardTypeByNumber = (cardNumber) => {
      const Cards = creditCardType(cardNumber);
      return Cards.length > 0 ? Cards[0].type : "";
    };

    formInputs.forEach((input) => {
      switch (input.uniqueId) {
        case "expiry-date":
          const dateRes = input.value.split("/");
          tempFormPost.expiry_month = dateRes[0];
          tempFormPost.expiry_year = 20 + dateRes[1];
          break;
        case "first-name":
          tempFormPost.first_name = input.value;
          break;
        case "last-name":
          tempFormPost.last_name = input.value;
          break;
        case "company-name":
          tempFormPost.organization_name = input.value;
          break;
        case "email":
          tempFormPost.email = input.value;
          break;
        case "postcode":
        case "postcode-persistent":
          tempFormPost.postcode = input.value;
          break;
        case "postal-address":
          tempFormPost.address = input.value;
          break;
        case "suburb":
          tempFormPost.suburb = input.value;
          break;
        case "message":
          tempFormPost.message = input.value;
          break;
        case "card-number":
          tempFormPost.card_number = input.value.split(/\s/).join("");
          tempFormPost.card_type = getCreditCardTypeByNumber(tempFormPost.card_number);
          break;
        case "card-name":
          tempFormPost.name_on_card = input.value;
          break;
        case "cvv":
          tempFormPost.ccv = input.value;
          break;
        case "phone-number":
        case "phonenumber":
        case "phone":
          tempFormPost.phone = input.value;
          break;
        default:
          break;
      }
    });

    setIsFormValid(formInputs.every((input) => input.isValid === "true")); //set form valid if all input is valid
    setFormPost(tempFormPost);
  };

  //check for other-amounts are moved to inputs/amountInput.js to handle it locally inside the component
  // const handleInputChangeAmount = (e) => {
  // if (e.target.value === "" || e.target.value === "$" || e.target.value === "$ " || e.target.value === "$  ") {
  //   setCheckBoxView(false);
  // } else {
  //   setCheckBoxView(true);
  // }
  // if (donationFixedProcessingCost === 0) setChecked(false); //when donationFixedProcessingCost doesn't have value, always reset the checkbox when user input other amount
  // e.target.value = e.target.value.replace(/\$/g, "").trim();
  // e.target.value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
  // setDonationAmount(e.target.value);
  // if (e.target.value > 0) {
  //   e.target.dataset.isValidate = true;
  // } else {
  //   e.target.dataset.isValidate = false;
  // }
  // };

  //credit card number format and validation
  const handleInputCardNumber = (e) => {
    e.target.maxLength = 19;
    e.target.value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    e.target.value = e.target.value
      .replace(/\s/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();
    if (e.target.value !== "" && validateCard(e.target.value, acceptedCards)) {
      e.target.dataset.isValidate = true;
    } else {
      e.target.dataset.isValidate = false;
    }
    let code = e.which;
    if ((code < 48 || code > 57) && code > 31) {
      return false;
    }
    return true;
  };

  const handleInputExpiryDate = (e) => {
    let d = new Date();
    // let curr_date = d.getDate();
    const curr_month = d.getMonth() + 1;
    const curr_year = d.getFullYear();
    e.target.maxLength = 5;
    e.target.value = e.target.value
      .replace(/^(\d\d)(\d)$/g, "$1/$2")
      .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
      .replace(/[^\d/]/g, "");
    const dateRes = e.target.value.split("/");
    const expiry_month = parseInt(dateRes[0]);
    const expiry_month_valid = expiry_month > 0 && expiry_month < 13;
    const expiry_year = parseInt(20 + dateRes[1]);
    const d1 = new Date(expiry_year, expiry_month);
    const d2 = new Date(curr_year, curr_month);
    e.target.dataset.isValidate = expiry_month_valid && e.target.value !== "" && e.target.value.length === 5 && d1 >= d2;
  };

  //Australian phone number maxLength 10 digit
  const handleInputPhoneNumber = (e) => {
    e.target.maxLength = 10;
    e.target.value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    if (e.target.value !== "" && e.target.value.length >= 10) {
      e.target.dataset.isValidate = true;
      // tempFormPost.phone = e.target.value;
      setFormPost({ ...formPost, phone: e.target.value });
    } else {
      e.target.dataset.isValidate = e.target.dataset.required === "false";
    }
  };

  //Cvv maxLength 3 or 4 digit
  const handleInputCvv = (e) => {
    e.target.maxLength = 4;
    e.target.value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    e.target.dataset.isValidate = e.target.value ? /\d{3,4}/.test(e.target.value) : e.target.dataset.required === "false";
  };

  //Australian postcode max 4 digit
  const handleInputPostCode = (e) => {
    e.target.maxLength = 4;
    e.target.value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    if (e.target.value !== "" && e.target.value.length >= 4) {
      e.target.dataset.isValidate = true;
    } else {
      e.target.dataset.isValidate = e.target.dataset.required === "false";
    }
  };

  const handleInputPostalAddress = (e) => {
    e.target.dataset.isValidate = e.target.value ? regexValidPostalAddress.test(e.target.value) : e.target.dataset.required === "false";
  };

  const handleInputSuburb = (e) => {
    e.target.dataset.isValidate = e.target.value ? regexValidSuburb.test(e.target.value) : e.target.dataset.required === "false";
  };

  const handleInputEmail = (e) => {
    e.target.dataset.isValidate = regexValidEmail.test(e.target.value);
  };

  const handleInputJustCheckEmpty = (e) => {
    e.target.dataset.isValidate = e.target.value !== "";
  };

  //text only
  const handleInputText = (e) => {
    e.target.dataset.isValidate = e.target.value !== "" && regexTextOnly.test(e.target.value);
  };

  return {
    formElement,
    stickyHoney,
    setStickyHoney,
    isFormValid,
    validateForm,
    onChangeInput,
    formPost,

    activeSelection,
    setActiveSelection,
    isFormLoaded,
    setIsFormLoaded,
  };
};

export default useFormInputsValidationContext;
