import { useEffect, useState } from "react";
import { CREDIT_CARD_LABEL, CONTRIBUTOR_INDIVIDUAL, DONATIONFREEUENCY_ONCE, DONATIONFREQUENCY_MONTHLY } from "../utilities/constants";
import { getValidUrl } from "../utilities/urlHelper";

/**
 * This hooks is to handle global donation realated field's state management
 * And field interaction between states that needs to be used in multiple components.
 *
 * In this component fields usually related to front-end behaviour not handling the post data to server.
 */
const useDonationContext = () => {
  const [donationAmount, setDonationAmount] = useState(window?.atDonationAsk?.askAmount ?? "10"); //Make sure to use it like setDonationAmount(value)
  const [overwriteStatement, setOverwriteStatement] = useState(window?.atDonationAsk?.impactStatement ?? ""); //new
  const [overwriteAskAmount, setOverwriteAskAmount] = useState(window?.atDonationAsk?.askAmount ?? ""); //new
  // const [totalDonationAmount, setTotalDonationAmount] = useState(donationAmount); // moved to useTotalDonationAmountAndProcessingCost
  // const [donationProcessingCost, setDonationProcessingCost] = useState(donationAmount.toFixed(2)); // moved to useTotalDonationAmountAndProcessingCost, original const [amountPercentage, setAmountPercentage] = useState(donationAmount);
  // const [percentage, setPercentage] = useState(donationAmount); // removed because of no usage

  const [shouldCoverDonationProcessingCost, setShouldCoverDonationProcessingCost] = useState(false); //checked, setChecked
  const [donationFixedProcessingCost, setDonationFixedProcessingCost] = useState(0);
  const [donationPercentProcessingCost, setDonationPercentProcessingCost] = useState(0); //donationPercent, setDonationPercent

  const [donationFrequency, setDonationFrequency] = useState(window?.atDonationAsk?.donationFrequency ?? ""); // use to be called selectionTab or frequency or logicalField
  const [paymentMethod, setPaymentMethod] = useState(CREDIT_CARD_LABEL);
  // const [isApplePay, setIsApplePay] = useState(paymentMethod === APPLE_PAY_LABEL); /removed to use paymentMethod === APPLE_PAY_LABEL
  // const [isPaypal, setIsPaypal] = useState(paymentMethod === PAYPAL_LABEL); //removed to use paymentMethod === PAYPAL_LABEL
  const [contributor, setContributor] = useState(CONTRIBUTOR_INDIVIDUAL);

  const [showAddDollarAmount, setShowAddDollarAmount] = useState(true); //checkBoxView, setCheckBoxView
  // const [checked, setChecked] = useState(false); //checked, setChecked
  // const [donationPost, setDonationPost] = useState(); //donatoinPost, setdonatoinPost
  // const [isFormLoaded, setIsFormLoaded] = useState(false); //isLoad, setIsLoad //move to useFormInputsValidation.js
  // const [activeSelection, setActiveSelection] = useState(""); //activeTab, setActiveTab, logicalField, setLogicalField (updates, when selection field updates) //move to useInputsFormValidation.js

  //set up success and failure pages
  const [failurePage, setFailurePage] = useState(""); //failurePage, setfailurePage
  const [successPage, setSuccessPage] = useState(""); //successPage, setsuccessPage
  const [recurringPaymentSuccessPage, setRecurringPaymentSuccessPage] = useState("");
  const getSuccessPage = (forceAbosolute = false) => {
    if (typeof window === "undefined") return "";
    if (recurringPaymentSuccessPage && donationFrequency !== "once") {
      return getValidUrl(recurringPaymentSuccessPage, forceAbosolute);
      //return recurringPaymentSuccessPage.startsWith("/") ? recurringPaymentSuccessPage : "/" + recurringPaymentSuccessPage;
    }

    return getValidUrl(successPage, forceAbosolute);
    // const relativeUrl = successPage.startsWith("/") ? successPage : "/" + successPage;
    // return forceAbosolute ? relativeUrl : getAbsoluteUrl(relativeUrl);
  };

  const getFailurePage = (forceAbosolute = false) => {
    if (typeof window === "undefined") return "";
    return getValidUrl(failurePage, forceAbosolute);
    // const relativePath = failurePage.startsWith("/") && failurePage.startsWith() ? failurePage : "/" + failurePage;
    // return forceAbosolute ? relativePath : getAbsoluteUrl(relativePath);
  };

  // useEffect(
  //   function onPaymentMethodUpdate() {
  //     setIsPaypal(paymentMethod === PAYPAL_LABEL);
  //     setIsApplePay(paymentMethod === APPLE_PAY_LABEL);
  //   },
  //   [paymentMethod]
  // );

  const [totalDonationAmount, donationProcessingCost, donationIncreasedProcessingPercentage] = useTotalDonationAmountAndProcessingCost(
    donationAmount,
    donationFixedProcessingCost,
    donationPercentProcessingCost,
    shouldCoverDonationProcessingCost
  );

  useListenDonationAmountByElementWithIDAsOtherAmount(setDonationAmount);

  //provide a way for external script to interact with react state
  window.setOtherAmount = (value) => {
    setDonationAmount(value);
    window.atDonationAsk = window.atDonationAsk || {};
    window.atDonationAsk.otherAmount = value;
  };
  window.setImpactStatement = (value) => {
    setOverwriteStatement(value);
    window.atDonationAsk = window.atDonationAsk || {};
    window.atDonationAsk.impactStatement = value;
  };
  window.setAskAmount = (value) => {
    setOverwriteAskAmount(value);
    window.atDonationAsk = window.atDonationAsk || {};
    window.atDonationAsk.askAmount = value;
  };
  window.setDonationFrequency = (value) => {
    //  eslint-disable-next-line eqeqeq
    const frequency = 0 == value ? DONATIONFREEUENCY_ONCE : DONATIONFREQUENCY_MONTHLY;
    setDonationFrequency(frequency);
    window.atDonationAsk = window.atDonationAsk || {};
    window.atDonationAsk.donationFrequency = value;
  };

  // run whenever activeTab is changed
  // useEffect(() => {
  //   if (isFormLoaded) {
  //     validateForm();
  //   }
  // }, [activeSelection]);

  // let donationState = {
  //   thank_you_url: () => (window ? determineSuccessPage() : ""),
  // };

  //isValidation
  // const validateForm = () => {};

  return {
    donationAmount,
    setDonationAmount,
    overwriteStatement,
    overwriteAskAmount,

    totalDonationAmount,
    // setTotalDonationAmount,
    donationPercentProcessingCost,
    setDonationPercentProcessingCost,
    donationFixedProcessingCost,
    setDonationFixedProcessingCost,
    donationProcessingCost,
    donationIncreasedProcessingPercentage,
    // setDonationProcessingCost,

    donationFrequency,
    setDonationFrequency,
    paymentMethod,
    setPaymentMethod,
    contributor,
    setContributor,

    //donation form field interaction
    shouldCoverDonationProcessingCost,
    setShouldCoverDonationProcessingCost,
    showAddDollarAmount,
    setShowAddDollarAmount,

    //form fields interaction
    // isFormLoaded,
    // setIsFormLoaded,
    // activeSelection,
    // setActiveSelection,

    //success and failure page
    failurePage,
    setFailurePage,
    getFailurePage,
    successPage,
    setSuccessPage,
    getSuccessPage,
    recurringPaymentSuccessPage,
    setRecurringPaymentSuccessPage,
  };
};

export default useDonationContext;

//hooks

/**
   * there are external script will update this "#other-amount",
   * we need to use that input as single source of truth for value in the donation form
   * When using an external event, please make sure it triggers the "input" event, not just value change
   * 
    const otherAmountInput = document.getElementById("other-amount");
    if (otherAmountInput) {
        otherAmountInput.value = "$100"; // Update the value

        // Create and dispatch an 'input' event
        const event = new Event('input', { bubbles: true });
        otherAmountInput.dispatchEvent(event);
    }
   */
const useListenDonationAmountByElementWithIDAsOtherAmount = (setDonationAmount) => {
  // This useEffect only should run once to bind the handleInputChange function to the input element change event
  useEffect(
    function onComponentMount() {
      const otherAmountInput = document.getElementById("other-amount");
      const handleInputChange = (event) => {
        if (event.target.value) {
          const newAmount = event.target.value;
          const cleaned = newAmount.replace(/\$/g, "").trim();
          setDonationAmount(cleaned);
        }
      };

      if (otherAmountInput) {
        otherAmountInput.addEventListener("input", handleInputChange);
      }

      return () => {
        if (otherAmountInput) {
          otherAmountInput.removeEventListener("input", handleInputChange);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setDonationAmount, document.getElementById("other-amount")] //needed to make sure the input element is loaded
  );
};

// update the total donation amount and processing cost by the donation amount, fixed processing cost, percent processing cost, and shouldCoverDonationProcessingCost
const useTotalDonationAmountAndProcessingCost = (
  donationAmount,
  donationFixedProcessingCost,
  donationPercentProcessingCost,
  shouldCoverDonationProcessingCost
) => {
  const [donationProcessingCost, setDonationProcessingCost] = useState(parseFloat(donationAmount).toFixed(2));
  const [totalDonationAmount, setTotalDonationAmount] = useState(parseFloat(donationAmount).toFixed(2));
  const [donationIncreasedProcessingPercentage, setDonationIncreasedProcessingPercentage] = useState(donationProcessingCost / donationAmount);

  useEffect(() => {
    const getIncreasedPercentage = (donationAmount, donationFixedProcessingCost, donationPercentProcessingCost) => {
      if (window?.processingFeeAmount) return "0";
      if (donationFixedProcessingCost) return parseFloat(donationFixedProcessingCost / donationAmount).toString();
      if (donationPercentProcessingCost) return (parseFloat(donationPercentProcessingCost) / 100).toString();

      return "0";
    };

    const donationAmountNumber = parseFloat(donationAmount);
    const defaultFixedProcessingCost = parseFloat(donationFixedProcessingCost);
    const amountIncresed = window.processingFeeAmount || defaultFixedProcessingCost || (donationAmountNumber * donationPercentProcessingCost) / 100;
    const acturalIncreasedProcessingPercentage = getIncreasedPercentage(donationAmount, donationFixedProcessingCost, donationPercentProcessingCost);

    setTotalDonationAmount(
      shouldCoverDonationProcessingCost ? (amountIncresed + donationAmountNumber).toFixed(2) : parseFloat(donationAmount).toFixed(2)
    );
    setDonationIncreasedProcessingPercentage(acturalIncreasedProcessingPercentage);
    setDonationProcessingCost(amountIncresed.toFixed(2));
  }, [
    donationAmount,
    donationFixedProcessingCost,
    donationPercentProcessingCost,
    shouldCoverDonationProcessingCost,
    setTotalDonationAmount,
    setDonationProcessingCost,
    setDonationIncreasedProcessingPercentage,
  ]);

  return [totalDonationAmount, donationProcessingCost, donationIncreasedProcessingPercentage];
};
