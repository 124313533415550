const size = {
  xsm: "320px",
  sm: "768px",
  md: "1024px",
  lg: "1290px",
  xl: "1590px",
};

export const breakpoints = {
  xsm: `(min-width: ${size.xsm})`,
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  xlg: `(min-width: ${size.xlg})`,
};

//change these would affect dynamics data collection
export const APPLE_PAY_LABEL = "Apple pay";
export const PAYPAL_LABEL = "Paypal";
export const CREDIT_CARD_LABEL = "Credit card";

export const POST_APPLE_PAY = "Apple pay";
export const POST_PAYPAL = "Paypal";
export const POST_CREDIT = "Credit";
export const POST_PAYMENT_MAP = {
  [APPLE_PAY_LABEL]: POST_APPLE_PAY,
  [PAYPAL_LABEL]: POST_PAYPAL,
  [CREDIT_CARD_LABEL]: POST_CREDIT,
};

//these keys would be read by backend to process and send data to dynamics
export const POST_LABEL = {
  CSRF_TOKEN: "csrf_token",
  AMOUNT: "amount",
  TOTAL_AMOUNT: "total_amount",
  STICKY_HONEY: "sticky_honey",
  INCREASE_PERCENTAGE: "increase_percentage",
  INCREASE_DONATION: "increase_donation",
  PROCESSING_COST: "processing_cost",
  STATE: "state",
  CONTRIBUTOR: "contributor",
  FREQUENCY: "frequency",
  PAYMENT_METHOD: "payment_method",
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  ORGANIZATION_NAME: "organization_name",
  EMAIL: "email",
  POSTCODE: "postcode",
  ADDRESS: "address",
  SUBURB: "suburb",
  NAME_ON_CARD: "name_on_card",
  CARD_TYPE: "card_type",
  CARD_NUMBER: "card_number",
  EXPIRY_MONTH: "expiry_month",
  EXPIRY_YEAR: "expiry_year",
  CCV: "ccv",
  PHONE: "phone",
  MESSAGE: "message",
  PAYPAL_DESC: "paypal_desc",

  //UTM Params
  UTM_CONTENT: "utm_content",
  UTM_MEDIUM: "utm_medium",
  UTM_NAME: "utm_name",
  UTM_CAMPAIGN: "utm_campaign",
  UTM_SOURCE: "utm_source",
  UTM_TERM: "utm_term",
  C_ID: "c_id",
  COSMOS_CAMPAIGN_ID_C: "cosmos_campaign_id_c",
  ADOBE_TARGET: "adobe_target",

  //page redirects
  PAGE_URL: "page_url",
  THANK_YOU_URL: "thank_you_url",

  //for apple pay update
  REQUEST_ID: "request_id",
  PAYMENT_INTENT_ID: "payment_intent_id",

  //for paypal update
  TRANSACTION: "transaction",
  TOKEN: "token",
  PAYER_ID: "PayerID",
};

//change these might affect contentful rendering correct content
export const CONTRIBUTOR_INDIVIDUAL = "individual";
export const CONTRIBUTOR_ORGANISATION = "organisation";
export const DONATIONFREEUENCY_ONCE = "once";
export const DONATIONFREQUENCY_MONTHLY = "monthly";

export const CONTENTFUL_FIXED_INPUTS = {
  CONTRIBUTOR_INDIVIDUAL: "individual",
  CONTRIBUTOR_ORGANISATION: "organisation",
  DONATIONFREEUENCY_ONCE: "once",
  DONATIONFREQUENCY_MONTHLY: "monthly",
};
