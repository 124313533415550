import "url-search-params-polyfill";
import { empty } from "./helper";

/**
 * Strip the protocol from an url
 * take BASE_URL e.g. http://localhost:3000
 * Convert it into "localhost:3000"
 *
 * @param {string} url
 * @returns {String}
 */
export const stripProtocolFromUrl = (url) => {
  if (empty(url)) return "";
  return url.replace(/(^\w+:|^)\/\//, "");
};

/**
 * https://starlight.org.au/xxx/xxx  => starlight.org.au
 * http://localhost:8001/xxx/xxx => localhost:8001
 * some-relative/path => window?.location.host or process.env.GATSBY_BASE_URL or ""
 *
 * Get the domain from a URL
 * @param {string} url - The URL to extract the domain from
 * @returns {string} - The domain extracted from the URL
 */
export const getUrlDomain = (url) => {
  if (empty(url)) return "";
  const domain = stripProtocolFromUrl(url);
  //check if domain starts with "/", if true remove the starting "/", and then take the part before the first "/" as domain.
  //e.g. /donation => donation, /donation/xxx => donation, /www.example.com/xxx => www.example.com
  const result = domain.replace(/^\//, "").split("/")[0];
  // write a regex to check if the domain is valid, so "donation" would be invalid, and return current domain instead.
  const domainRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,6}$/;

  //if no domain means its relative path url like /donation, so we return current domain instead.
  if (empty(result) || !domainRegex.test(result)) {
    return window?.location?.host ?? process.env.GATSBY_BASE_URL ?? "";
  }

  return result;
};

/**
 * using window?.localhost.hostname first, then fallback to process.env.GATSBY_BASE_URL
 * check if url's domain is internal or external domain, returns a boolean
 *
 * needs to handle comparism with relative path /donation with http://localhost:8000/donation
 * if http://localhost:8000/ is current domain this needs to show true, as its not external.
 *
 * @param {*} url
 * @returns {boolean|null}
 */
export const isExternalUrl = (url) => {
  if (empty(url) || typeof window === "undefined") return null;
  const domain = getUrlDomain(url);
  const currentDomain = getUrlDomain(window?.location.host ?? process.env.GATSBY_BASE_URL);
  //if mailto: or tel: or its external
  if (url.startsWith("mailto:") || url.startsWith("tel:")) return true;
  //when domain is empty, it's a relative path url, so it's not external
  return domain !== currentDomain && domain !== "";
};

/**
 * Stripe the domain name and protocal leave the path
 * https://starlight.org.au/donation => /donation
 *
 * @param {*} url
 * @return {*}
 */
export const stripDomainAndProtocalFromUrl = (url) => {
  if (empty(url)) return url;
  const domain = getUrlDomain(url);
  const strippedProtocalUrl = stripProtocolFromUrl(url);
  const strippedUrl = strippedProtocalUrl.replace(`${domain}`, "");

  return strippedUrl;
};

/**
 *
 * @param {*} url
 * @returns {object}
 * e.g.
 * {
 *   'param1':'xxx',
 *   'param2':'xxx'
 * }
 */
export const getUrlParameters = () => {
  if (empty(window) || empty(window?.location)) return {};
  const hashFreeUrl = window?.location.toString().replace(window?.location.hash, "");
  const parameters = {};
  hashFreeUrl.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    parameters[key] = value;
  });
  return parameters;
};

/**
 * get the url hash
 * @returns {string}
 */
export const getUrlHash = () => {
  if (empty(window) || empty(window?.location)) return "";
  return window?.location.hash;
};

/**
 * get url parameters
 * e.g. https://starlight.org.au?test1=1&test2=2
 * getUrlParameterBykey(test1) //outputs "1"
 * getUrlParameterBykey(test2) //outputs "2"
 * getUrlParameterBykey(test3) //outputs null
 * getUrlParameterBykey(test3, 3) //outputs 3
 *
 * @param {string} key
 * @param {*} defaultValue
 * @returns
 */
export const getUrlParameterBykey = (key, defaultValue = null) => {
  const parameters = getUrlParameters();
  return parameters[key] || defaultValue;
};

/**
 * @param {string[]|object} keys ["transaction", "token", "PayerID"] or {"transaction":null, "token":null, "PayerId":null}
 * @return {array} {"transaction": value, "token": value, "PayerID": value}
 */
export const getUrlParameterBykeys = (keys) => {
  const parameters = getUrlParameters();
  if (Array.isArray(keys)) {
    const result = {};
    keys.forEach((key) => {
      result[key] = parameters[key] || null;
    });
    return result;
  } else if (typeof keys === "object") {
    const result = {};
    for (const key in keys) {
      result[key] = parameters[key] || keys[key];
    }
    return result;
  } else {
    return {};
  }
};

/**
 * The URLSearchParams interface is used to work with the query string of a URL. It is well supported in modern browsers, but older versions of some browsers do not support it.
 * According to the MDN Web Docs(https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams#browser_compatibility), URLSearchParams is not supported in:
 * Internet Explorer (all versions)
 * Edge before version 17
 * Firefox before version 44
 * Chrome before version 49
 * Safari before version 10.1
 * Opera before version 36
 * iOS Safari before version 10.3
 * Android Browser before version 56
 * Opera Mobile before version 36
 * Chrome for Android before version 49
 * Firefox for Android before version 44
 * UC Browser for Android before version 11.8
 * Samsung Internet before version 5.0
 *
 * Please note that these are historical versions and most users are likely using versions that do support URLSearchParams.
 * However, if you need to support these older versions, using a polyfill like url-search-params-polyfill is a good solution.
 *
 * @param {*} params
 * @returns
 */
export const newURLSearchParams = (params) => {
  return new URLSearchParams(params);
};

/**
 * Always ensure the url is valid for redirection purpose in react.
 *
 * input: www.sample.com, output: //www.sample.com
 * input: some_child_page/page output: /some_child_page/page
 * input: some_child_page/page, true output: //origin.domain.com/some_child_page (origin.domain is getting from window.location.origin)
 *
 * @param {*} url
 * @param {*} forceAbsolute
 * @returns
 */
export const getValidUrl = (url, forceAbsolute = false) => {
  // Check if the URL starts with http or https
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  }

  // If forceAbsolute is true, prepend the window.location.host and add the leading 'https://'
  if (forceAbsolute) {
    return "https://" + window.location.host + "/" + url.replace(/^\//, ""); // remove leading slash if it existss
  }

  //if url is www.xxx or xxx.com, prepend "https://"
  if (url.indexOf(".") > -1) {
    return "https://" + url;
  }

  // If the URL does not start with '/', prepend '/'
  if (!url.startsWith("/")) {
    return "/" + url;
  }

  // Return the URL as it is if it already starts with '/'
  return url;
};


/**
 * Turning object into URL parameters
 * 
 * e.g. {param1: "value1", param2: "value2"} => "?param1=value1&param2=value2"
 * @param {*} params 
 * @returns 
 */
export const turnObjToUrlParams = (params) => {
  let paramStr = "";
  //url encode the parameters
  if (params) {
    let encodedParameters = Object.keys(params).map(
      key => `${key}=${encodeURIComponent(params[key])}`
    );

    paramStr = "?" + encodedParameters.join("&");
  }

  return paramStr;
}